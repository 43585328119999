.header {
  padding: unset;
  //background-image: url("../../images/LandingPgBackground.png");
  background-color: #005bb5;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.title,
.category,
.description {
  color: white;
}

.landing-header {
  width: 100%;
  background-image: url('../../images/LandingPgBackground.png');
  background-color: #17202B;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  margin: -2px 0 -20px 0;
  padding: 40px 0;
}

.landing-header * {
  color: #ffffff !important;
}